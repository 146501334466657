import {ClientsComponent, MainComponent, FooterComponent, ResponsiveAppBar, BoaDescriptionComponent, ExpertiseComponent, PodcastComponent} from './Components';
import './App.css';
import { ThemeProvider } from './UI/ThemeProvider';

export default function App() {

  return (
    <ThemeProvider>
      <div>
        <ResponsiveAppBar/>
          <MainComponent/>
          <ClientsComponent/>
          {/*<ProjectsComponent/>*/}
          <BoaDescriptionComponent/>
          <ExpertiseComponent/>
          <PodcastComponent/>
          <FooterComponent/>
      </div>
    </ThemeProvider>
  );
}
