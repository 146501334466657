import { rem } from "polished";

export const baseFontSize = 16;

const boaTheme = {
  fontSize: {
    base: baseFontSize,
    display1: rem("60px"),
    display2: rem("42px"),
    h1: rem("40px"),
    h2: rem("36px"),
    h3: rem("28px"),
    h4: rem("24px"),
    h5: rem("20px"),
    body1: rem("18px"),
    body2: rem("16px"),
    body3: rem("14px"),
    caption: rem("12px"),
  },
  font:{
    baseFont: "'Quicksand', sans-serif"
  },
  fontWeight: {
    light: 300,
    normal: 400,
    medium: 500,
    bold: 700,
  },
  color: {
    black: "#000000",
    white: "#ffffff",
    blue: "#26547C",
    cream: "#f7f5ee",
    green: "#0ba77d",
    darkBlue: "#132e5c"
  }
};

export type ThemeType = typeof boaTheme;
type Color = keyof ThemeType["color"];

export const color = (colorName: Color) => boaTheme.color[colorName];

export default boaTheme as ThemeType;
