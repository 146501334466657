import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { SxProps } from '@mui/material';
import Background from '../images/background.svg'
import { useTheme } from 'styled-components';

export default function MainComponent(): JSX.Element {

  const theme = useTheme();
  
  const style: SxProps = {
    backgroundImage: `url(${Background})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    width: '100%',
    minHeight: '84vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  }

  const h1Style: SxProps = {
    color: theme.color.green, 
    fontFamily: theme.font.baseFont, 
    fontWeight: theme.fontWeight.bold,
    animation: 'fadeInBottom 1.5s ease-out',
    '@keyframes fadeInBottom': {
      from: {
        opacity: 0,
        transform: 'translateY(20%)'
      },
      to: {
        opacity: 1,
        transform: 'translateY(0)'
      }
    }
  }

  const h2Style: SxProps = {
    color: theme.color.blue, 
    fontFamily: theme.font.baseFont,
    fontWeight: theme.fontWeight.normal,
    fontSize: '120%',
    justifyContent: 'center',
    alignItems: 'center',
    animation: 'fadeInBottom 3s ease-out',
    '@keyframes fadeInBottom': {
      from: {
        opacity: 0,
        transform: 'translateY(80%)'
      },
      to: {
        opacity: 1,
        transform: 'translateY(0)'
      }
    }
  }

  return (
    <Box sx={style}>
        <Typography variant='h1' gutterBottom sx={h1Style}>
          BoA
        </Typography>
        <Typography variant='h2' gutterBottom sx={h2Style}>
          L'accompagnement DATA complet
        </Typography>
    </Box>
  );
}
