import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { SxProps, ThemeProvider, createTheme } from "@mui/material/styles";
import DataEng from "../images/cards/de.svg";
import DataSci from "../images/cards/ds.svg";
import DataAn from "../images/cards/da.svg";
import { useTheme } from "styled-components";
import { ExpertiseSpec } from "../Types/commons.types";

declare module '@mui/system' {
  interface BreakpointOverrides {
    // Your custom breakpoints
    laptop: true;
    tablet: true;
    mobile: true;
    desktop: true;
    // Remove default breakpoints
    xs: false;
    sm: false;
    md: false;
    lg: false;
    xl: false;
  }
}

export default function ExpertiseComponent(): JSX.Element {
  const theme = useTheme();

  const expertise: Array<ExpertiseSpec> = [{
    key: 'data-engineering',
    title: "Data Engineering",
    img: DataEng,
    text: "Construire et entretenir une plateforme robuste pour gérer et analyser de grands volumes de données, tout en veillant à leur qualité, leur sécurité et leur coût."
  },{
    key: 'data-science',
    title: "Data Science",
    img: DataSci,
    text: "Explorer les données pour en extraire des informations utiles et prédictives, afin d'aider à prendre des décisions éclairées et à anticiper les tendances."
  },{
    key: 'data-analysis',
    title: "Data Analyse",
    img: DataAn,
    text: "Analyser les données pour identifier des patterns, des tendances et des insights pertinents, facilitant la compréhension et l'optimisation des processus."
  }];

  const style: SxProps = {
    backgroundColor: theme.color.cream,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    width: "100vw",
    paddingBottom: "100px",
    paddingLeft: '20px',
    paddingRight: '20px',
  };

  const h3Style: SxProps = {
    color: theme.color.blue,
    fontFamily: theme.font.baseFont,
    fontWeight: theme.fontWeight.medium,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    fontSize: theme.fontSize.h3,
    paddingTop: '50px',
  };

  const h5Style: SxProps = {
    color: theme.color.green,
    fontFamily: theme.font.baseFont,
    fontWeight: theme.fontWeight.bold,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    fontSize: theme.fontSize.h5,
  };

  const text: SxProps = {
    color: theme.color.darkBlue,
    fontFamily: theme.font.baseFont,
    fontWeight: theme.fontWeight.light,
    textAlign: "justify", 
    margin: "5%",
    paddingBlock: "40px",
  };

  const cardStyle: SxProps = {
    height: "330px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  };

  return (
    <Box sx={style}>
      <Typography gutterBottom sx={h3Style}>
        Une Expertise Complète
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
          alignItems: "center",
          flexFlow: 'row wrap',
          marginTop: '30px'
        }}
      >
        <ThemeProvider
          theme={createTheme({
            breakpoints: {
              values: {
                laptop: 1024,
                tablet: 640,
                mobile: 0,
                desktop: 1280,
              },
            },
          })}
        >
          <Grid container spacing={2} sx={{ flexGrow: 1 }}>
            {expertise.map(exp => (
              <Grid key={exp.key} laptop={4} desktop={4} tablet={12} mobile={12}>
                <Card sx={cardStyle}>
                  <CardContent>
                    <Typography gutterBottom sx={h5Style}>
                      {exp.title}
                    </Typography>
                    <Grid container sx={{ flexGrow: 1 }}>
                      <Grid laptop={8} desktop={8} tablet={8} mobile={8}>
                        <Typography sx={text}>{exp.text}</Typography>
                      </Grid>
                      <Grid laptop={4} desktop={4} tablet={4} mobile={4}>
                        <img src={exp.img} alt={exp.key} height="200px"/>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </ThemeProvider>
      </Box>
    </Box>
  );
}
