import Box from '@mui/material/Box';
import { ClientLogoSpec } from '../Types/commons.types';

import LPILogo from '../images/clients/lpi_logo.svg';
import BNPLogo from '../images/clients/bnp_logo.svg';
import QareLogo from '../images/clients/qare_logo.svg';
import SNCFLogo from '../images/clients/sncf_logo.svg';
import LuniiLogo from '../images/clients/lunii_logo.svg';
import NelsonLogo from '../images/clients/nelson_logo.svg';
import LorealLogo from '../images/clients/loreal_logo.svg';
import JolimoiLogo from '../images/clients/jolimoi_logo.svg';
import FimalacLogo from '../images/clients/fimalac_logo.svg';
import OffstageLogo from '../images/clients/offstage_logo.svg';
import MooncardLogo from '../images/clients/mooncard_logo.svg';
import SynchroneLogo from '../images/clients/synchrone_logo.svg';
import MicrobiomeLogo from '../images/clients/microbiome_logo.svg';
import SportHeroesLogo from '../images/clients/sportheroes_logo.svg';
import HorseRepublicLogo from '../images/clients/horse_republic_logo.svg';


export default function ClientsComponent(): JSX.Element {
  const pictures: Array<ClientLogoSpec> = [
    { image: SNCFLogo, title: 'SNCF' },
    { image: QareLogo, title: 'Qare' },
    { image: FimalacLogo, title: 'Fimalac' },
    { image: MooncardLogo, title: 'Mooncard' },
    { image: JolimoiLogo, title: 'Jolimoi' },
    { image: SportHeroesLogo, title: 'Sport Heroes' },
    { image: SynchroneLogo, title: 'Synchrone' },
    { image: OffstageLogo, title: 'Offstage' },
    { image: HorseRepublicLogo, title: 'Horse Republic' },
    { image: LPILogo, title: 'Learning Planet Institute' },
    { image: BNPLogo, title: 'BNP' },
    { image: LuniiLogo, title: 'Lunii' },
    { image: NelsonLogo, title: 'Nelson' },
    { image: LorealLogo, title: 'Loreal' },
    { image: MicrobiomeLogo, title: 'Microbiome' },
  ];

  return (
    <>
      <style>
        {`
          @keyframes scroll {
            0% { transform: translateX(0); }
            100% { transform: translateX(-50%); }
          }
          .scroll-container {
            display: flex;
            width: 200%;
            animation: scroll 40s linear infinite;
          }
        `}
      </style>
      <Box sx={{
        height: '150px',
        margin: 'auto',
        overflow: 'hidden',
        position: 'relative',
        width: '100%',
        display: 'flex',
        alignItems: 'center'
      }}>
        <Box component="div" className="scroll-container">
          {pictures.map((logo, index) => (
            <img src={logo.image} alt={logo.title} style={{ maxWidth: '6%', height: '150px', margin: '0 25px' }} key={index}/>
          ))}
          {pictures.map((logo, index) => (
            <img src={logo.image} alt={logo.title} style={{ maxWidth: '6%', height: '150px', margin: '0 25px' }} key={index + pictures.length}/>
          ))}
        </Box>
      </Box>
    </>
  );
}
