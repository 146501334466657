import { ThemeProvider as StyledThemeProvider } from "styled-components";
import boaTheme from "./Theme";
import React from "react";



export const ThemeProvider: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({ children }) => {
  return (
    <StyledThemeProvider theme={boaTheme}>
      <>
        {children}
      </>
    </StyledThemeProvider>
  );
};
