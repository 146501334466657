import { Box, Typography } from '@mui/material';
import { useTheme } from 'styled-components';

export default function FooterComponent (): JSX.Element{
  const theme = useTheme();
 return (
  <Box sx={{backgroundColor: theme.color.darkBlue, color: theme.color.green, minHeight: '12vh', display: 'flex', alignItems: "center", flexDirection: "column", justifyContent: 'center'}}>
    <Typography>Copyright BoA Data ©{new Date().getFullYear()}. All Rights Reserved.</Typography>
  </Box>
 )

}
