import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Modal, SxProps } from '@mui/material';
import { ContactModalProps } from '../Types/commons.types';

// TODO: Faire le style et ajouter le fait de pouvoir choisir soit d'aller sur calendly soit un typeform de contact 

export default function ContactModalComponent(props: ContactModalProps): JSX.Element {

  const style: SxProps = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  return (
    <Modal
    open={props.open}
    onClose={props.handleClose}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
  >
    <Box sx={style}>
      <Typography id="modal-modal-title" variant="h6" component="h2">
        Text in a modal
      </Typography>
      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
        Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
      </Typography>
    </Box>
  </Modal>
  );
}
