import { SxProps } from "@mui/material/styles";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Slider from 'react-slick';
import { useTheme } from "styled-components";
import Background from '../images/background_podcast.svg'

// Importation des styles de la carousel slick
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

import PodcastImage1 from '../images/podcast/podcast_episode_1.png';
import PodcastImage2 from '../images/podcast/podcast_episode_2.png';
import PodcastImage3 from '../images/podcast/podcast_episode_3.png';
import PodcastImage4 from '../images/podcast/podcast_episode_4.png';

import SpotifyLogo from '../images/spotify.svg';
import AppleLogo from '../images/apple.svg';
import DeezerLogo from '../images/deezer.svg';

export default function PodcastComponent(): JSX.Element {
  const theme = useTheme();

  var items = [
    {
      guest: "Clément Tailleur",
      description: "Dans l'épisode 1 de Moment Données, Clément revient sur son expérience en tant que <strong>Head of Data</strong> chez Qare. Startup passée de 15 à 150 employés en quelques jours, quelles <strong>problématiques techniques et stratégiques</strong> rencontre un pôle Data au sein d'une structure qui révolutionne son marché",
      imgSrc: PodcastImage1,
      links: {
        apple: "https://podcasts.apple.com/fr/podcast/1-construire-et-piloter-la-data-chez-qare-d%C3%A9fis-et/id1472056704?i=1000658458662",
        spotify: "https://podcasters.spotify.com/pod/show/moment-donnes/episodes/1---Construire-et-Piloter-la-Data-chez-Qare-Dfis-et-Stratgies-e2kn69b",
        deezer: "https://deezer.page.link/FZVQuni8yD38jfkdA"
      }
    },
    {
      guest: "Lucas Tighilt",
      description: "Dans cet épisode, Lucas revient sur le métier de <strong>Revops</strong>. Comment et pourquoi, ces 10 dernières années, il est passé du fichier excel partagé à 150 personnes à un CRM optimisé et drivé par de la donnée ? Revops ? C'est <strong>l'optimisation des ressources pour générer du revenu</strong> (marketing, sales, ops)",
      imgSrc: PodcastImage2,
      links: {
        apple: "https://podcasts.apple.com/fr/podcast/2-lessor-du-revops-par-la-data-du-fichier-excel-au/id1472056704?i=1000660147702",
        spotify: "https://podcasters.spotify.com/pod/show/moment-donnes/episodes/2---Lessor-du-RevOps-par-la-Data--Du-Fichier-Excel-au-CRM-Data-Driven-e2l82qe",
        deezer: "https://deezer.page.link/74VCRcsNCKe67ygw5"
      }
    },
    {
      guest: "Thomas Vannier",
      description: "Thomas Vannier, <strong>Head of Data</strong> chez StaffMe revient sur la promesse de son entreprise : permettre aux employeurs de trouver du renfort sans avoir à le chercher. Un business de Supply & Demand avec un enjeu de <strong>matching</strong> clé, construit sur une stack solide et des outils bien chosis",
      imgSrc: PodcastImage3,
      links: {
        apple: "https://podcasts.apple.com/fr/podcast/3-dans-quelques-semaines-il-y-aura-les-jo-et-on/id1472056704?i=1000664453809",
        spotify: "https://podcasters.spotify.com/pod/show/moment-donnes/episodes/3---Dans-quelques-semaines--il-y-aura-les-JO-et-on-risque-davoir-pas-mal-doffre-e2mrdp8/a-abffaaj",
        deezer: "https://deezer.page.link/jLdrk2S6AGAitwB99"
      }
    },
    {
      guest: "Camille Gurcan",
      description: "Camille Gurcan, <strong>Head of Business Ops</strong> chez Mooncard, leader français de la gestion des notes de frais nous raconte son arrivée dans une scalup en hyper croissance post série C et sans BU Data. On parle de l’importance et l’urgence qu’il y a à aligner les outils qui gravitent autour de la base de données principale, via quelle culture, quel recrutement et quel mode de priorisation.",
      imgSrc: PodcastImage4,
      links: {
        apple: "https://podcasts.apple.com/fr/podcast/4-le-frein-number-one-%C3%A0-lusage-cest-la-compr%C3%A9hension/id1472056704?i=1000669918721",
        spotify: "https://open.spotify.com/episode/6QUHZXEa9zLVh9QOpVAjKG?si=C_11kXOsQVCVBg9qLBKC8w",
        deezer: "https://deezer.page.link/4atHgxJURTFudMqQA"
      }
    },
  ];

  const text: SxProps = {
    color: theme.color.darkBlue,
    fontFamily: theme.font.baseFont,
    fontWeight: theme.fontWeight.light,
    textAlign: "center", // Ajout du style pour le centrage
    fontSize: theme.fontSize.caption, // Taille de police plus petite
    paddingLeft: '30px',
    paddingRight: '30px',
  };
  
  // Réglages du slider
  const settings = {
    slidesToShow: 2,
    speed: 500,
    dots: true,
    responsive: [
      {
        breakpoint: 768, // Ajuste lorsque la taille de l'écran est inférieure à 768 pixels
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  };

  const style: SxProps = {
    backgroundImage: `url(${Background})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    width: "100vw",
    paddingBottom: "40px"
  };

  const h3Style: SxProps = {
    color: theme.color.blue,
    fontFamily: theme.font.baseFont,
    fontWeight: theme.fontWeight.medium,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    fontSize: theme.fontSize.h3,
    paddingTop: '50px',
  };

  const podcastStyle = {
    width: '80px',
    height: '80px',
    marginRight: '20px',
  };

  items = items.reverse();

  return (
    <Box sx={style}>
      <Typography gutterBottom sx={h3Style}>
        Le Podcast BoA
      </Typography>
      <Box sx={{ width: '100%', padding: 2}}>
        <Slider {...settings}>
          {items.map((item, i) => (
            <Box key={i} sx={{ padding: 1, paddingBottom: 5}}>
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: 500 }}>
                <img src={item.imgSrc} style={{ width: '100%', maxHeight: '330px', objectFit: 'contain', paddingBottom: '20px'}} />
                <Typography sx={text} dangerouslySetInnerHTML={{ __html: item.description }} />
                  <Box sx={{ display: 'flex', marginTop: 2 }}>
                    <a href={item.links.spotify} target="_blank"><img src={SpotifyLogo} style={podcastStyle} /></a>
                    <a href={item.links.deezer} target="_blank"><img src={DeezerLogo} style={podcastStyle} /></a>
                    <a href={item.links.apple} target="_blank"><img src={AppleLogo} style={podcastStyle}/></a>
                  </Box>
              </Box>
            </Box>
          ))}
        </Slider>
      </Box>
    </Box>
  );
}