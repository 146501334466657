import * as React from 'react';
import Box from "@mui/material/Box";
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import { SxProps } from "@mui/material/styles";
import { useTheme } from "styled-components";
import { useInView } from 'react-intersection-observer';
import Background from '../images/background_description.svg';

import LoopIcon from '../images/description/loop.png';
import PlanIcon from '../images/description/plan.png';
import BuildIcon from '../images/description/build.png';
import TrainingIcon from '../images/description/training.png';
import OptimisationIcon from '../images/description/optimisation.png';
import Typography from '@mui/material/Typography';

export default function BoaDescriptionComponent() {
  const timelineRef = React.useRef(null);
  const theme = useTheme();

  const style: SxProps = {
    backgroundImage: `url(${Background})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    width: '100%',
    minHeight: '84vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  }

  const h3Style: SxProps = {
    color: theme.color.blue,
    fontFamily: theme.font.baseFont,
    fontWeight: theme.fontWeight.medium,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    fontSize: theme.fontSize.h3,
    paddingTop: '10px',
    paddingBottom: '30px',
  };

  const title: SxProps = {
    color: theme.color.green,
    fontFamily: theme.font.baseFont,
    fontWeight: theme.fontWeight.bold,
  };

  const text: SxProps = {
    color: theme.color.darkBlue,
    fontFamily: theme.font.baseFont,
    fontWeight: theme.fontWeight.light,
  };

  const [ref1, inView1] = useInView({ triggerOnce: true });
  const [ref2, inView2] = useInView({ triggerOnce: true });
  const [ref3, inView3] = useInView({ triggerOnce: true });
  const [ref4, inView4] = useInView({ triggerOnce: true });
  const [ref5, inView5] = useInView({ triggerOnce: true });

  const steps = [
    { time: '', icon: LoopIcon, title: 'Audit', description: "Analyse détaillée des besoins data spécifiques à l'entreprise.", ref: ref1, inView: inView1 },
    { time: '', icon: PlanIcon, title: 'Plannification', description: "Définition des objectifs data clairs et planification des initiatives stratégiques.", ref: ref2, inView: inView2 },
    { time: '', icon: BuildIcon, title: 'Développement', description: "Sélection et mise en place des meilleures technologies et outils pour les besoins identifiés.", ref: ref3, inView: inView3 },
    { time: '', icon: TrainingIcon, title: 'Accompagnement', description: "Formation des équipes client et mise en place d'un soutien continu pour assurer le transfert de compétences.", ref: ref4, inView: inView4 },
    { time: '', icon: OptimisationIcon, title: 'Optimisation', description: "Évaluation régulière des processus et des technologies pour garantir une amélioration continue.", ref: ref5, inView: inView5 },  
  ];

  return (
    <Box ref={timelineRef}>
      <Typography gutterBottom sx={h3Style}>
        Notre Accompagnement
      </Typography>
      <Timeline position="alternate" sx={style}>
        {steps.map((step, index) => (
          <TimelineItem key={index} style={{ paddingBottom: '20px' }}>
            <TimelineOppositeContent>
              {step.time}
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineConnector style={{ backgroundColor: 'transparent' }}/>
              <TimelineDot sx={{backgroundColor: '#FFFFFF'}}>
                <img src={step.icon} style={{ width: '65px', height: '65px' }} />
              </TimelineDot>
              <TimelineConnector style={{ backgroundColor: 'transparent' }}/>
            </TimelineSeparator>
            <TimelineContent>
              <Typography sx={title} component="span">
                {step.title}
              </Typography>
              <Typography sx={text} ref={step.ref} style={{ opacity: step.inView ? 1 : 0, transition: 'opacity 1s ease-in' }}>{step.description}</Typography>
            </TimelineContent>
          </TimelineItem >
        ))}
      </Timeline>
    </Box>
  );
}
